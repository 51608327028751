import { ErrorSnackbar } from '@application/components';
import { FeedbackContext, UserContext } from '@application/contexts';
import { downloadRemoteFile } from '@application/utils/file-utils';
import { extractErrorCodes } from '@application/utils/urql-utils';
import { useGenerateSingleAddressSearchMutation } from '@domain/index';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSiteLookupFilters from '../filters/useSiteLookupFilters';
import { ReportAggregation } from '@domain/graphql.types';

const useSiteLookup = () => {
  const { t } = useTranslation();

  const { isOnFreePlan, refreshUser, tenantSubscription } = useContext(UserContext);
  const { setFeedback } = useContext(FeedbackContext);

  const { filters, updateFilter } = useSiteLookupFilters();

  const { data, error, fetching, generateReport } = useGenerateSingleAddressSearchMutation();

  const downloadReport = useCallback(
    (aggregation: ReportAggregation) => {
      if (!filters?.address) {
        return;
      }

      generateReport({
        input: {
          aggregation,
          coordinates: filters.address?.coordinates || { latitude: 0, longitude: 0 },
          fullAddress: filters.address?.fullAddress,
          range: filters.radius,
          stateCode: filters.address?.regionCode || '',
        },
      });
    },
    [filters, generateReport],
  );

  useEffect(() => {
    if (!data?.generateSingleAddressSearchReport.link) {
      return;
    }

    downloadRemoteFile(data.generateSingleAddressSearchReport.link);
    refreshUser();
  }, [data, refreshUser]);

  useEffect(() => {
    const errors = extractErrorCodes(error);

    if (!errors.length) {
      return;
    }

    setFeedback(
      <ErrorSnackbar
        message={t('siteLookup.error.downloadReport.message')}
        onClose={() => setFeedback(null)}
        title={t('siteLookup.error.downloadReport.title')}
      />,
    );
  }, [error, setFeedback, t]);

  return {
    actions: {
      downloadReport,
      updateFilter,
    },
    state: {
      filters,
      isDownloadingSitesReport: fetching,
      isUserOnFreePlan: isOnFreePlan,
      remainingSearches: tenantSubscription?.remainingSearches,
    },
  };
};

export default useSiteLookup;
