// https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery?page=1&tab=scoredesc#tab-top
export const downloadRemoteFile = async (link: string) => {
  const response = await fetch(link);
  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const fileName = decodeURI(new URL(link).pathname.split('/').pop() || '');

  downloadLocalFile(url, fileName);

  window.URL.revokeObjectURL(url);
};

export const downloadLocalFile = async (url: string, download: string) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = download;
  document.body.appendChild(a);
  a.click();
};
